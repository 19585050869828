<template>
    <div v-if="show && inlineShow" class="explainer--wrapper">
        <div class="explainer--inner">
            <p class="h2">Välkommen till Wordy!</p>
            <p>Wordy bygger på SAOLs ord på 5 bokstäver. Din uppgift är att lista ut vilket slumpmässigt utvalt ord det är som gömmer sig. Du har 6 försök på dig.</p>
            <p><span class="box box--green"></span> <strong>Grönt</strong> betyder att bokstaven är placerad på exakt rätt plats</p>
            <p><span class="box box--yellow"></span> <strong>Gult</strong> betyder att bokstaven finns med i ordet, men inte är placerad på rätt plats</p>
            <p>På tangentbordet får du även hjälp med att se vilka bokstäver du använt, men som inte är med i ordet. Dessa blir utgråade.</p>
            <p>Enjoy!</p>
            <button class="play" @click="close()">Låt mig spela!</button>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        show:Boolean
    },
    data(){
        return{
            inlineShow:true
        }
    },
    methods:{
        close(){
            this.inlineShow = false;
            localStorage.setItem('wordify_helper',false)
        }
    }
}
</script>

<style scoped>
.explainer--wrapper{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    padding:10px;
    z-index: 999;
    display:flex;
    align-items: center;
    justify-content: center;
}
.explainer--wrapper::after{
    content:'';
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:var(--main-bg-color);
    opacity:.9;
    z-index: -1;
}
.explainer--inner{
    padding:1rem;
    border-radius:10px;
    background:white;
    color:var(--keyboard-btn-color);
    max-width:800px;
    margin:0 auto;
}
.h2{
    font-size:1.25rem;
    font-weight:bold;
}
.box {
    width:1.25rem;
    height:1.25rem;
    border-radius:2px;
    display:inline-block;
    position:relative;
    top:4px;
    margin-right:5px;
}
.box--green{
    background-color:var(--keyboard-btn-color-correct);
}
.box--yellow{
    background-color:var(--keyboard-btn-color-present);
}
.play{
    background-color:var(--keyboard-btn-color-correct);
    padding:1rem 4rem;
    margin:5px auto 0;
}
</style>