<template>
    <div class="grid-row justify-center relative">
        
        <box v-for="i in 5" :key="i" :letter="letters[i-1]" :evaluation="evaluation[i-1] ? evaluation[i-1].type : ''"  />
        
        <div v-if="error" class="error">
            Ordet finns inte i den svenska ordlistan
        </div>
    </div>
</template>

<script>
import Box from './Box.vue'
export default {
    props:{
        "letters":String,
        "evaluation":Array,
        "error":Boolean
    },
    components: { Box },
    computed:{

    }
}
</script>

<style scoped>
.error{
    background:var(--error);
    font-size:.85rem;
    border-radius:.25rem;
    padding:.5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index:2;
    margin-top:4px;
}
.error::after{
    content:'';
    position:absolute;
    left:50%;
    transform:translateX(-50%);
    border-left:5px solid transparent;
    border-right:5px solid transparent;
    border-bottom:5px solid var(--error);
    bottom:100%;
}
</style>