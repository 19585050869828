<template>
    <div :class="ev" class="letter">{{ letter }}</div>
</template>

<script>
export default {
    props:['letter','ev'],
}
</script>

<style scoped>
.letter{
    border:1px solid rgba(255,255,255,.1);
    border-radius:.5rem;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    vertical-align: middle;
    box-sizing: border-box;
    color: var(--tile-text-color);
    text-transform: uppercase;
    user-select: none;
}
</style>