<template>
    <div id="keyboard">

        <div v-for="(r,i) in rows" :key="i" class="row">
            <div v-if="i == 2" class="spacer-0.5"></div>
            
            <button v-for="(b,j) in r" :key="j" :evaluation="checkStatus(b)" @click="sendChar(b)">{{ b }}</button>
            
            <button v-if="i == 0" class="spacer-1.5" @click="sendChar(8)">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path fill="var(--color-tone-1)" d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"></path></svg>
            </button>
            
            <button v-if="i == 2" class="spacer-2.5" @click="sendChar(13)">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 172 172"><g fill="#08121b"><path d="M37.84,24.08c-9.45834,0 -17.2,7.74166 -17.2,17.2v89.44c0,9.45834 7.74166,17.2 17.2,17.2h96.32c9.45834,0 17.2,-7.74166 17.2,-17.2v-89.44c0,-9.45834 -7.74166,-17.2 -17.2,-17.2zM37.84,30.96h96.32c5.73958,0 10.32,4.58042 10.32,10.32v89.44c0,5.73958 -4.58042,10.32 -10.32,10.32h-96.32c-5.73958,0 -10.32,-4.58042 -10.32,-10.32v-89.44c0,-5.73958 4.58042,-10.32 10.32,-10.32zM116.96,86c-1.24059,-0.01754 -2.39452,0.63425 -3.01993,1.7058c-0.62541,1.07155 -0.62541,2.39684 0,3.46839c0.62541,1.07155 1.77935,1.72335 3.01993,1.7058h6.88v13.76h-32.97563l7.88781,-7.88781c1.01742,-0.98897 1.32333,-2.50111 0.77034,-3.80778c-0.55299,-1.30667 -1.85145,-2.13983 -3.26971,-2.098c-0.89371,0.02663 -1.74194,0.40014 -2.365,1.04141l-13.45766,13.45765c-0.85151,0.64892 -1.35239,1.65727 -1.35502,2.72786c-0.00263,1.07059 0.49328,2.08139 1.34158,2.73449c0.0067,0.0045 0.01342,0.00898 0.02015,0.01344l13.45094,13.45094c0.86281,0.89867 2.14404,1.26068 3.34956,0.94641c1.20552,-0.31427 2.14696,-1.2557 2.46122,-2.46122c0.31427,-1.20552 -0.04774,-2.48675 -0.94641,-3.34956l-7.88781,-7.88781h36.41563c1.89978,-0.00019 3.43981,-1.54022 3.44,-3.44v-20.64c-0.00019,-1.89978 -1.54022,-3.43981 -3.44,-3.44z"></path></g></svg>
                enter
            </button>
        </div>

    </div>
</template>

<script>
export default {
    props:{
        letters:Array,
        words:Array
    },
    data(){
        return{
            rows:[
                [
                    'q','w','e','r','t','y','u','i','o','p','å'
                ],
                [
                    'a','s','d','f','g','h','j','k','l','ö','ä'
                ],
                [
                    'z','x','c','v','b','n','m'
                ]
            ]
        }
    },
    computed:{
        correctLetters(){
            let arr = [];
            for(let i=0;i<this.words.length;i++){
                if(this.words[i] && this.words[i].length > 0){
                    let word = this.words[i];
                    word.split("");
                    for(let j=0;j<word.length;j++){
                        if(this.letters[i][j]){
                            if(this.letters[i][j].type.indexOf('correct') > -1){
                                if(arr.indexOf(word[j]) < 0){
                                    arr.push(word[j])
                                }
                            }
                        }
                    }
                }
            }
            return arr;
        },
        presentLetters(){
            let arr = [];
            for(let i=0;i<this.words.length;i++){
                if(this.words[i] && this.words[i].length > 0){
                    let word = this.words[i];
                    word.split("");
                    for(let j=0;j<word.length;j++){
                        if(this.letters[i][j]){
                            if(this.letters[i][j].type.indexOf('present') > -1){
                                if(arr.indexOf(word[j]) < 0){
                                    arr.push(word[j])
                                }
                            }
                        }
                    }
                }
            }
            return arr;
        },
        wrongLetters(){
            let arr = [];
            for(let i=0;i<this.words.length;i++){
                if(this.words[i] && this.words[i].length > 0){
                    let word = this.words[i];
                    word.split("");
                    for(let j=0;j<word.length;j++){
                        if(this.letters[i][j]){
                            if(this.letters[i][j].type == 'absent'){
                                if(arr.indexOf(word[j]) < 0){
                                    arr.push(word[j])
                                }
                            }
                        }
                    }
                }
            }
            return arr;
        }
    },
    methods:{
        checkStatus(letter){
            if(this.correctLetters.indexOf(letter) > -1){
                return 'correct'
            } else if (this.wrongLetters.indexOf(letter) > -1){
                return 'absent'
            } else if(this.presentLetters.indexOf(letter) > -1){
                return 'present'
            }
        },
        sendChar(char){
            this.$emit('getChar', char)
        }
    }
}
</script>

<style>
  #keyboard {
    user-select: none;
    height:40vh;
  }
  
  .row {
    display: flex;
    width: 100%;
    margin: 0 auto 6px;
    touch-action: manipulation;
  }
  
  button {
    font-family: inherit;
    font-weight: bold;
    border: 0;
    padding: 0;
    margin: 0 6px 0 0;
    height: 11vh;
    max-height:50px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    background-color: var(--keyboard-btn);
    color: var(--keyboard-btn-color);
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    -webkit-tap-highlight-color: rgba(0,0,0,0.3);
  }

  button:focus {
    outline: none;
  }

  button.fade {
    transition: background-color 0.1s ease, color 0.1s ease;
  }
  
  button:last-of-type {
    margin: 0;
  }
  
  .spacer-0\.5{
    flex: 0.5;
  }
  
  .spacer-1 {
    flex: 1;
  }
  
  .spacer-1\.5{
    flex: 1.5;
    font-size: 12px;
  }
  .spacer-2 {
    flex: 2;
  }
  .spacer-2\.5 {
    flex: 2.5;
  }

  @media(max-width:767px){
    #keyboard {
        height: auto;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px;
        margin-bottom: 10px;
    }
    button {
        margin-right: 2px;
    }
    .row {
        margin-bottom: 2px;
    }
  }
</style>